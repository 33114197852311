<template>
    <BlockContainer
        :background-color="data.backgroundColor"
        :bottom-angle="data.bottomAngle"
        :angle-background-color="data.nextBackgroundColor"
    >
        <div class="gap-x-8 py-8 md:py-20 lg:flex">
            <div class="relative mb-12 md:mb-0 lg:w-1/4">
                <div class="lg:top-32">
                    <div class="flex flex-col">
                        <div class="mb-4 flex justify-between">
                            <div>
                                FILTER BY ({{
                                    filteredDesignResourceItems.length
                                }}
                                RESULTS)
                            </div>
                            <a class="cursor-pointer" @click="resetFilters">
                                Reset
                            </a>
                        </div>
                        <ProductFilterRefinementListContent
                            v-if="data.designStyleFilters?.length > 0"
                            title="Design Style"
                            :expanded-default="false"
                            :mobile-menu-open="false"
                            :items="designStyleFilterItems"
                            @toggle="toggleDesignStyleFilter"
                        />
                        <ProductFilterRefinementListContent
                            v-if="data.industryFilters?.length > 0"
                            title="Industry"
                            :expanded-default="false"
                            :mobile-menu-open="false"
                            :items="industryFilterItems"
                            @toggle="toggleIndustryFilter"
                        />
                        <ProductFilterCta
                            class="mt-8 hidden md:block"
                            link-url="/free-sample-pack"
                            link-label="Send Me Stuff"
                            text="Get your hands on our packaging before you place your order. It just makes sense."
                            heading="Free Samples"
                        />
                    </div>
                </div>
            </div>
            <div class="lg:w-3/4">
                <h2 class="mb-8">{{ data.title }}</h2>
                <div
                    class="grid grid-cols-1 gap-5 md:grid-cols-3 md:px-0 lg:grid-cols-3"
                >
                    <div
                        v-for="item in filteredDesignResourceItems"
                        :key="'item-' + item.title"
                        class="flex flex-col justify-start rounded-lg border border-gray-100 bg-white"
                    >
                        <img
                            v-if="item.image"
                            :src="item.image.src"
                            :alt="item.image.alt"
                            class="relative rounded-t-lg"
                        />
                        <div class="relative">
                            <TagGroup
                                v-if="item.tag && item.tag != ''"
                                :tags="[item.tag]"
                                color="drip"
                                class="flex-wrap pl-5 pt-16"
                            />
                            <h3 class="pl-5 pr-5 pt-5">{{ item.title }}</h3>
                            <div
                                class="pl-5 pr-5 pt-1 font-bold uppercase text-drip"
                            >
                                {{ item.designerName }}
                            </div>
                            <div class="pl-5 pr-5 pt-1">
                                {{ item.designerLocation }}
                            </div>
                            <div class="pl-5 pr-5 pt-3">
                                {{ item.description }}
                            </div>
                            <div v-if="item.link" class="pb-5 pl-5 pr-5 pt-3">
                                <NuxtLink :to="item.link.url">
                                    {{ item.link.label }}
                                </NuxtLink>
                            </div>
                            <img
                                v-if="item.designerPhoto"
                                :src="item.designerPhoto.src"
                                :alt="item.designerPhoto.alt"
                                class="absolute left-4 top-[-40px] h-[80px] w-[80px] rounded-[80px]"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BlockContainer>
</template>

<script setup lang="ts">
import DesignResourceGalleryType from '~/types/DesignResourceGalleryType';
import DesignResourceItemType from '~/types/DesignResourceItemType';
import BlockContainer from '~/components/BlockContainer.vue';
import ProductFilterRefinementListContent from '~/components/page-building/product-filter/components/ProductFilterRefinementListContent.vue';
import ProductFilterCta from '~/components/page-building/product-filter/components/ProductFilterCta.vue';

const props = defineProps<{
    data: DesignResourceGalleryType;
}>();

const designStyleFilterItems = ref<any>([]);
const industryFilterItems = ref<any>([]);

const designStyleFilters = ref<any>([]);
const industryFilters = ref<any>([]);

const filteredDesignResourceItems = ref<DesignResourceItemType[]>(
    props.data.resourceItems,
);
const selectedFilterCount = ref<any>(0);

onMounted(() => {
    resetFilters();
});

const resetFilters = () => {
    designStyleFilterItems.value = [];
    props.data.designStyleFilters.forEach((filter: string) => {
        designStyleFilters.value[filter] = false;
        designStyleFilterItems.value.push({
            value: filter,
            isRefined: false,
        });
    });
    industryFilterItems.value = [];
    props.data.industryFilters.forEach((filter: string) => {
        industryFilters.value[filter] = false;
        industryFilterItems.value.push({
            value: filter,
            isRefined: false,
        });
    });
    selectedFilterCount.value = 0;

    nextTick(() => {
        filterDesignResourceItems();
    });
};

const filterDesignResourceItems = () => {
    filteredDesignResourceItems.value = [];
    props.data.resourceItems.forEach((item: DesignResourceItemType) => {
        const filteredOut =
            processFilter(
                item.designStyle,
                props.data.designStyleFilters,
                designStyleFilters.value,
            ) ||
            processFilter(
                item.industry,
                props.data.industryFilters,
                industryFilters.value,
            );

        if (!filteredOut) {
            filteredDesignResourceItems.value.push(item);
        }
    });
};

const processFilter = (
    itemFilterValue: string[],
    filters: string[],
    filterValues: any,
) => {
    if (!includesActiveFilter(filters, filterValues)) {
        return false;
    }

    let filterHit = false;
    filters.forEach((filter: string) => {
        if (itemFilterValue.includes(filter) && filterValues[filter]) {
            filterHit = true;
        }
    });

    return !filterHit;
};

const includesActiveFilter = (filterOptions: string[], filterValues: any) => {
    let active = false;
    filterOptions.forEach((filter: string) => {
        if (filterValues[filter]) {
            active = true;
        }
    });
    return active;
};

const toggleDesignStyleFilter = (filter: string) => {
    designStyleFilters.value[filter] = !designStyleFilters.value[filter];
    selectedFilterCount.value += designStyleFilters.value[filter] ? 1 : -1;
    toggleFilterItem(designStyleFilterItems, filter);
    filterDesignResourceItems();
};

const toggleIndustryFilter = (filter: string) => {
    industryFilters.value[filter] = !industryFilters.value[filter];
    selectedFilterCount.value += industryFilters.value[filter] ? 1 : -1;
    toggleFilterItem(industryFilterItems, filter);
    filterDesignResourceItems();
};

const toggleFilterItem = (items: any, filter: string) => {
    for (let i = 0; i < items.value.length; i++) {
        if (items.value[i].value == filter) {
            items.value[i].isRefined = !items.value[i].isRefined;
        }
    }
};
</script>
