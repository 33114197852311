<template>
    <DesignResourceGallery v-editable="blok" :data="designResourceGalleryContent" />
</template>

<script setup lang="ts">
import DesignResourceGalleryType from '@/types/DesignResourceGalleryType';
import DesignResourceItemType from '@/types/DesignResourceItemType';
import ImageType from '@/types/ImageType';
import LinkType from '~/types/LinkType';
import DesignResourceGallery from '~/components/page-building/design-resource-gallery/DesignResourceGallery.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const resourceItems = props.blok.designers
    ? props.blok.designers.map((item: any) => {
          return {
              title: item.content.title,
              designerName: item.content.designer_name,
              designerLocation: item.content.designer_location,
              image: {
                  title: item.content.image.name,
                  alt: item.content.image.alt,
                  src: item.content.image.filename,
              } as ImageType,
              designerPhoto: {
                  title: item.content.designer_photo.name,
                  alt: item.content.designer_photo.alt,
                  src: item.content.designer_photo.filename,
              } as ImageType,
              description: item.content.description,
              tag: item.content.tag,
              link: {
                  label: 'Learn More',
                  url: '/' + item.full_slug,
                  target: '',
              } as LinkType,
              designStyle: item.content.design_style
                  ? item.content.design_style.split('\n')
                  : [],
              industry: item.content.industry
                  ? item.content.industry.split('\n')
                  : [],
          } as DesignResourceItemType;
      })
    : [];

const designResourceGalleryContent = computed<DesignResourceGalleryType>(() => {
    return {
        title: props.blok.title,
        designStyleFilters:
            props.blok.design_style_filters &&
            props.blok.design_style_filters != ''
                ? props.blok.design_style_filters.split('\n')
                : [],
        industryFilters:
            props.blok.industry_filters &&
            props.blok.industry_filters != ''
                ? props.blok.industry_filters.split('\n')
                : [],
        resourceItems,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
    };
});
</script>
